import { useQuery } from "@apollo/client";
import { differenceInDays } from "date-fns";

import { USER_BASIC_QUERY } from "graphql/user/queries";

export default function useMemberBadges(id?: string) {
  const {
    data: userData,
    loading,
    error,
  } = useQuery(USER_BASIC_QUERY, {
    variables: { id },
  });
  const joinedAt = userData?.user?.snapshot?.joinedAt;
  return {
    isNewMember: joinedAt
      ? differenceInDays(new Date(), new Date(joinedAt)) < 30
      : false,
    isMemberLeader: userData?.user?.memberLeader,
    loading,
    error,
  };
}
